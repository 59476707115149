import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TaskApiService from '../../api/TaskApiService';
import dayjs from 'dayjs';

const PrinterFriendlyTask = () => {
    const params = useParams();
    const [taskId] = useState(params.taskId);
    const [taskData, setTaskData] = useState({});
    const [taskHistory, setTaskHistory] = useState([]);

    const taskService = new TaskApiService();

    useEffect(() => {
        async function fetchData () {
            const thisTaskData = await taskService.getTask(taskId);
            setTaskData(thisTaskData.data);
            const thisTaskHistory = await taskService.getTaskHistory(taskId);
            setTaskHistory(thisTaskHistory.data);
        }
        void fetchData();
    }, [taskId]);

    return <div>
        <div>
            <b>
                Task ID:&nbsp;
                <a href={'/Task/' + taskId}>{taskId}</a>
            </b>
        </div>
        <div>
            <b>
                Short description:&nbsp;
                <a href={'/Task/' + taskId}>{taskData?.shortDescription}</a>
            </b>
        </div>
        <div>
            <table id={'printTaskDataTable'} cellSpacing={'0'} cellPadding={'3'} border={'1'}>
                <tbody>
                    <tr>
                        <td>Reported By</td>
                        <td>{taskData?.reportedUser?.username}</td>
                    </tr>
                    <tr>
                        <td>Reported On</td>
                        <td>{dayjs(taskData?.reportedDate).format('MM/DD/YYYY hh:mm:ss A')}</td>
                    </tr>
                    <tr>
                        <td>Project/Location</td>
                        <td>{taskData?.project?.pjName}</td>
                    </tr>
                    <tr>
                        <td>Category</td>
                        <td>{taskData?.category?.ctName}</td>
                    </tr>
                    <tr>
                        <td>Priority</td>
                        <td>{taskData?.priority?.prName}</td>
                    </tr>
                    <tr>
                        <td>Assigned To</td>
                        <td>{taskData?.assignedToUser?.username}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{taskData?.status?.stName}</td>
                    </tr>
                    {taskData?.customColumnData?.map((data) => {
                        return(
                            <tr key={data.id}>
                                <td>{data.columnName}</td>
                                <td>{data.columnValue}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <br/>
            <table id={'posts_table'} cellSpacing={3} cellPadding={0} border={0}>
                <tbody>
                    {taskHistory?.filter(data => data.taskChangeType === 'Comment' || data.taskChangeType === 'comment')
                        .map((data) => {
                            return (
                                <tr key={data.taskChange}>
                                    <td className={'cmt'}>
                                        <table width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td align={'left'}>
                                                        <span className={'pst'}>
                                                comment posted by {data.taskChangeUser} on {dayjs(data.taskChangeTime).format('MM/DD/YYYY hh:mm:ss A')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table border={0}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className={'cmt_text'}>
                                                            <p dangerouslySetInnerHTML={{ __html: data.taskChange }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            );
                        },
                        )}
                </tbody>
            </table>
        </div>
    </div>;
};

export default PrinterFriendlyTask;

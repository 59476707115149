import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import CTSidebar from '../CTSidebar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import PropTypes from 'prop-types';
import localSettingsManager from '../localSettings/localSettingsManager';
import * as settingConstants from '../constants/settingConstants';
import { useSelector } from 'react-redux';
import AuthUtil from './Utils/AuthUtil';

const Layout = (props) => {
    const [navMenuCollapsed, setNavMenuCollapsed] = useState(localSettingsManager.getSettingValue(settingConstants.navBarCollapsedSetting, false) === 'true');
    const { setSnackbarProperties, children } = props;
    function toggleNavMenu () {
        setNavMenuCollapsed(!navMenuCollapsed);
        localSettingsManager.setSettingValue(settingConstants.navBarCollapsedSetting, !navMenuCollapsed);
    }

    const userToken = useSelector((state) => state.correcttrack.userToken);
    const isAuthorized = useMemo(() => AuthUtil.isAuthorized(userToken), [userToken]);
    const isPrintTaskPage = useMemo(() => window.location.href.includes('/PrintTask/'), [window.location.href]);

    useEffect(() => {

    }, []);

    return (<div>
        {isAuthorized && !isPrintTaskPage && <NavMenu toggleNavMenu={toggleNavMenu} setSnackbarProperties={setSnackbarProperties} />}
        <div style={{display:'flex'}}>
            {isAuthorized && !isPrintTaskPage && <ProSidebarProvider>
                <CTSidebar navMenuCollapsed={navMenuCollapsed} />
            </ProSidebarProvider> }
            <div style={{ flex: 1 }}>
                <Container tag={'main'}>
                    {children}
                </Container>
            </div>
        </div>
    </div>);
};

Layout.propTypes = {
    children: PropTypes.object.isRequired,
};

export default Layout;

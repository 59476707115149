import React, { useEffect, useMemo, useState } from 'react';
import { Form, Label } from 'reactstrap';
import {
    Input,
    Select,
    MenuItem,
    FormControl,
    Grid,
    Box,
    useTheme,
    InputBase,
    Button,
    CircularProgress,
    Autocomplete,
    TextField,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { hazardRiskScore } from '../constants/hazardConstants';
import Image from 'mui-image';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import FileUploadModal from './Modal/FileUploadModal';
import fileUploadService from '../api/fileUploadService';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ConfirmDeleteDialogHazard from './Modal/ConfirmDeleteHazard';
import RiskControlModal from './Modal/RiskControlModal';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import OrgApiService from '../api/OrgApiService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    AutoLink,
    BlockQuote,
    Bold,
    ClassicEditor,
    Essentials, FontFamily, FontSize,
    Heading,
    Indent,
    Italic,
    Link, LinkEditing, LinkUI,
    List, MediaEmbed,
    Mention,
    Paragraph, Table,
    Undo,
} from 'ckeditor5';
import HazardApiService from '../api/HazardApiService';
import LocationApiService from '../api/LocationApiService';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RiskSourceApiService from '../api/RiskSourceApiService';
import { useSelector } from 'react-redux';
import AuthUtil from './Utils/AuthUtil';

const Hazard = (props) => {
    const { setSnackbarProperties } = props;
    const navigate = useNavigate();
    const params = useParams();
    const [hazardId, setHazardId] = useState(params.hazardId);
    const [currentHazard, setCurrentHazard] = useState(undefined);
    const [newHazard, setNewHazard] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState({ projectId: 0, projectName: '' });
    const [department, setDepartment] = useState('');
    const [process, setProcess] = useState('');
    const [job, setJob] = useState('');
    const [task, setTask] = useState('');
    const [equipment, setEquipment] = useState('');
    const [riskDescription, setRiskDescription] = useState('');
    const [selectedRiskSource, setSelectedRiskSource] = useState('');
    const [riskSources, setRiskSources] = useState([]);
    const [severity, setSeverity] = useState({ svId: 0, svName: '', svFactor: 1 });
    const [likelihood, setLikelihood] = useState({ lkId: 0, lkName: '', lkFactor: 1 });
    const [riskScoreUncontrolled, setRiskScoreUncontrolled] = useState({ riskScore: 0 });
    const [obligation, setObligation] = useState('');
    const [riskScoreControlled, setRiskScoreControlled] = useState({ riskScore: 0 });
    const [controls, setControls] = useState([]);
    const [likelihoods, setLikelihoods] = useState([]);
    const [severities, setSeverities] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showImageDeleteDialog, setShowImageDeleteDialog] = useState(false);
    const [showFileDeleteDialog, setShowFileDeleteDialog] = useState(false);
    const [currentFile, setCurrentFile] = useState({});
    const [controlTypes, setControlTypes] = useState([]);
    const [showAddControlModal, setShowAddControlModal] = useState(false);
    const [currentControl, setCurrentControl] = useState({});
    const [showControlDeleteDialog, setShowControlDeleteDialog] = useState(false);
    const [hazardAudits, setHazardAudits] = useState([]);
    const [departmentAutocomplete, setDepartmentAutocomplete] = useState(['']);
    const [processAutocomplete, setProcessAutocomplete] = useState(['']);
    const [jobAutocomplete, setJobAutocomplete] = useState(['']);
    const [taskAutocomplete, setTaskAutocomplete] = useState(['']);
    const [equipmentAutocomplete, setEquipmentAutocomplete] = useState(['']);
    const [title, setTitle] = useState('Create Risk');
    const [notes, setNotes] = useState('');
    const [changes, setChanges] = useState([]);
    const [isSignificant, setIsSignificant] = useState(false);
    const [showRiskChanges, setShowRiskChanges] = useState(false);

    const orgApiService = new OrgApiService();
    const hazardApiService = new HazardApiService();
    const locationApiService = new LocationApiService();
    const riskSourceApiService = new RiskSourceApiService();

    const userToken = useSelector((state) => state.correcttrack.userToken);
    const canChangeHazard = useMemo(() => AuthUtil.canChangeHazards(userToken), [userToken]);
    const userId = useMemo(() => AuthUtil.getUserId(userToken), [userToken]);
    const isSuperAdmin = useMemo(() => AuthUtil.isSuperAdmin(userToken), [userToken]);

    const labelStyle = { fontWeight: 'bold', fontSize: '18px' };

    const handleLoad = () => {
        setLoading(false);
    };

    const handlePrev = (event) => {
        event.preventDefault();

        if (images.length > 1) {
            setCurrentImage((currentImage - 1 + images.length) % images.length);
            setLoading(true);
        }
    };

    const handleNext = (event) => {
        event.preventDefault();

        if (images.length > 1) {
            setCurrentImage((currentImage + 1) % images.length);
            setLoading(true);
        }
    };

    const controlsColumns = [
        {
            field: 'hcDescription',
            headerName: 'Description',
            flex: 3,
            renderCell: (params) => {
                return params.row.controlLink ? <a target={'_blank'} href={`${params.row.controlLink}`} rel="noreferrer">{params.row.hcDescription}</a> : `${params.row.hcDescription}`;
            }
        },
        {
            field: 'ctName',
            headerName: 'Type',
            flex: 2,
            valueGetter: (params) => params.row.hcControlTypeNavigation.ctName,
    }, {
        field: 'ctFactor',
        headerName: 'Factor',
        flex: 1,
        valueGetter: (params) => params.row.hcControlTypeNavigation.ctFactor,
    }, {
        field: 'delete',
        headerName: '',
        flex: 1,
        renderCell: (params) => (<DeleteIcon
            style={{ cursor: 'pointer' }}
            onClick={() => onControlDeleteClick(params.row)}
        />),
    }];

    const [fileColumns] = useState([{
        field: 'filename',
        headerName: 'Filename',
        flex: 1,
        renderCell: (params) => {
            const handleDownloadClick = async (event, row) => {
                event.preventDefault();
                const response = await fileUploadService.downloadHazardAttachment(row, hazardId);

                // Create a blob object URL for the downloaded file
                const blob = new Blob([response.data], { type: row.contentType });
                const url = URL.createObjectURL(blob);

                // Create a temporary anchor tag and click it to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.download = row.filename;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                URL.revokeObjectURL(url);
            };

            return (<a href={`Hazard/${hazardId}`} target='_blank'
                onClick={(event) => handleDownloadClick(event, params.row)} rel="noreferrer">
                {params.value}
            </a>);
        },
    }, { field: 'description', headerName: 'Description', flex: 2 }, {
        field: 'delete',
        headerName: 'Delete',
        flex: 1,
        renderCell: (params) => (<DeleteIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleDeleteFile(params.row)}
        />),
    }]);

    function handleDeleteFile (file) {
        setCurrentFile(file);
        setShowFileDeleteDialog(true);
    }

    const onFileDeleteClick = (deleteValue) => {
        if (deleteValue) {
            fileUploadService.deleteHazardAttachment(currentFile.attachmentLinkId).then(() => {
                window.location.href = '/Hazard/' + hazardId;
            });
        }

        setShowFileDeleteDialog(false);
    };

    function onControlDeleteClick (control) {
        setCurrentControl(control);
        setShowControlDeleteDialog(true);
    }

    const handleDeleteControl = async (deleteValue) => {
        if (deleteValue) {
            if (currentControl.hcId > 0) {
                await hazardApiService.deleteHazardControl(currentControl.hcId);
                reloadControls();
            }
        }

        setShowControlDeleteDialog(false);
    };

    const [hazardAuditColumns] = useState([{
        field: 'updatedDate',
        headerName: 'Date Updated',
        flex: 1,
    }, { field: 'lastUpdatedUser', headerName: 'Updated By', flex: 1 }, {
        field: 'displayChanges',
        headerName: 'Changes',
        renderCell: (params) => (<div style={{ whiteSpace: 'pre-line' }}>
            {params.value}
        </div>),
        flex: 6,
    }]);

    function handleFileUpload (event) {
        if (event) {
            fileUploadService.uploadHazardAttachment(event, hazardId).then(() => {
                window.location.href = '/Hazard/' + hazardId;
            });
        }

        setShowUploadModal(false);
    }

    function handleAddControlModalClose () {
        setShowAddControlModal(false);
        reloadControls();
    }

    function reloadControls () {
        hazardApiService.getHazardData(hazardId).then((hazardResponse) => {
            const hazardObject = hazardResponse.data;

            if (!hazardObject) {
                return;
            }

            setControls(hazardObject.hazardControlDtos);
            calculateRiskScores(severity.svFactor, likelihood.lkFactor, hazardObject.hazardControlDtos);
        });
    }

    const onImageDeleteClick = (deleteValue) => {
        if (deleteValue) {
            fileUploadService.deleteHazardAttachment(images[currentImage].attachmentLinkId).then(() => {
                images.splice(currentImage, 1);
                if (currentImage > 0) {
                    setCurrentImage(currentImage - 1);
                }
                else {
                    setCurrentImage(0);
                }
            });
        }

        setShowImageDeleteDialog(false);
    };

    function handleFileDelete () {
        setShowImageDeleteDialog(true);
    }

    async function loadHazard() {
        setLoading(true);
        const riskSourcesResponse = await riskSourceApiService.getRiskSources();

        if(riskSourcesResponse.data) {
            setRiskSources(riskSourcesResponse.data);
        }

        if(isSuperAdmin) {
            const orgs = await orgApiService.getSelectableOrgs();

            if(orgs.success) {
                setOrganizations(orgs.data);
            }
        } else {
            const org = await orgApiService.getUsersOrg(userId);

            if(org.success) {
                let orgsEmptyArray = [];
                orgsEmptyArray.push(org.data);
                setOrganizations(orgsEmptyArray);
                setSelectedOrg(org.data.ogName);
                updateProjects(org.data.ogId);
            }
        }

        // Get all the Control Types to utilize for editing a risk control
        hazardApiService.getHazardControlTypes().then((controlTypesResponse) => {
            setControlTypes(controlTypesResponse.data);
        });

        // If the hazardId is 0, then we are going to be creating the Risk which means we can't get risk data.
        if (isHazardCreated) {
            setTitle('Edit Risk ' + hazardId);

            // Get the audits for this hazard, which include both hazard audits and hazard control audits
            hazardApiService.getHazardAudits(hazardId).then((auditResponse) => {
                setHazardAudits(auditResponse.data);
            });

            hazardApiService.getHazardData(hazardId).then(async (hazardResponse) => {
                const hazardObject = hazardResponse.data;

                if (!hazardObject) {
                    return;
                }
                const { likelihoodList, severityList } = await updateLikelihoodAndSeverities();
                loadAutoCompleteFields(hazardObject.hzProjectDto.pjId);

                setCurrentHazard(hazardObject);
                setNewHazard(hazardObject);

                setSelectedOrg(hazardObject.hzProjectDto.pjOrgDto.ogName);
                setSelectedProject({
                    projectId: hazardObject.hzProjectDto.pjId, projectName: hazardObject.hzProjectDto.pjName,
                });
                setDepartment(hazardObject.hzDepartmentName);
                setProcess(hazardObject.hzProcess);
                setJob(hazardObject.hzJob);
                setTask(hazardObject.hzTask);
                setEquipment(hazardObject.hzEquipmentId);
                setSelectedRiskSource(hazardObject.hzSystemAreaDto?.name);
                setObligation(hazardObject.hzObligation);
                setNotes(hazardObject.hzNotes);
                setIsSignificant(hazardObject.significant);

                setRiskDescription(hazardObject.hzRiskDescription ? hazardObject.hzRiskDescription : '');

                setControls(hazardObject.hazardControlDtos);

                let attachmentIndex = 0;
                const tempImages = [];
                const tempFiles = [];

                // Get all the hazard attachments to display
                hazardObject.hazardAttachmentLinkDtos.forEach((attachment) => {
                    attachmentIndex = attachmentIndex + 1;

                    const curAttachment = {
                        id: attachmentIndex,
                        url: attachment.url,
                        blobUrl: attachment.blobUrl,
                        description: attachment.description,
                        alt: 'Attachment ' + attachmentIndex,
                        attachmentLinkId: attachment.attachmentLinkId,
                        filename: attachment.filename,
                        contentType: attachment.contentType,
                    };

                    if (attachment.contentType.includes('image')) {
                        tempImages.push(curAttachment);
                    } else {
                        tempFiles.push(curAttachment);
                    }
                });

                setImages(tempImages);
                setFiles(tempFiles);

                // Find and set the likelihood for the current Hazard from the likelihoods API call we just did
                const hazardLikelihood = likelihoodList.find(likely => likely.lkId === hazardObject.hzLikelihood);
                setLikelihood({
                    lkId: hazardLikelihood?.lkId,
                    lkName: hazardLikelihood?.lkName,
                    lkFactor: hazardLikelihood?.lkFactor,
                });

                const hazardSeverity = severityList.find(sev => sev.svId === hazardObject.hzSeverity);
                setSeverity({
                    svId: hazardSeverity.svId,
                    svName: hazardSeverity.svName,
                    svFactor: hazardSeverity.svFactor,
                });

                if (hazardSeverity && hazardLikelihood) {
                    // Do some risk score calculations
                    calculateRiskScores(hazardSeverity.svFactor, hazardLikelihood.lkFactor, hazardObject?.hazardControlDtos);
                }

                // Update the list of projects based on the organization
                updateProjects(hazardObject.hzProjectDto.pjOrg);
            })
                .catch((e) => {
                    navigate('/Hazards');
                    setSnackbarProperties('Error while loading Hazard Data', 'error');
                });
        }
        else {
            void updateLikelihoodAndSeverities();
            calculateRiskScores(severity.svFactor, likelihood.lkFactor);
            setTitle('Create Risk');
        }
    }

    useEffect(() => {
        void loadHazard();
    }, [hazardId]);

    async function loadAutoCompleteFields(projectId) {
        hazardApiService.getAutoCompleteDepartments(projectId).then((departmentOptions) => {
            setDepartmentAutocomplete(departmentOptions.data);
        });

        hazardApiService.getAutoCompleteProcesses(projectId).then((processOptions) => {
            setProcessAutocomplete(processOptions.data);
        });

        hazardApiService.getAutoCompleteJobs(projectId).then((jobOptions) => {
            setJobAutocomplete(jobOptions.data);
        });

        hazardApiService.getAutoCompleteTasks(projectId).then((taskOptions) => {
            setTaskAutocomplete(taskOptions.data);
        });

        hazardApiService.getAutoCompleteEquipment(projectId).then((equipmentOptions) => {
            setEquipmentAutocomplete(equipmentOptions.data);
        });
    }

    async function updateLikelihoodAndSeverities() {
        // Get all the likelihoods
        const likelihoodResponse = await hazardApiService.getAllLikelihoods();

        let likelihoodList = [];

        // Create an empty likelihood
        likelihoodList.push({
            lkId: 0, lkName: '\u00A0', lkFactor: 1,
        });

        likelihoodList = likelihoodList.concat(likelihoodResponse.data);

        // Set the likelihoods and push in the empty element
        setLikelihoods(likelihoodList);

        // Get all the severities
        const severityResponse = await hazardApiService.getAllSeverities();

        let severityList = [];

        severityList.push({
            svId: 0, svName: '\u00A0', svFactor: 1,
        });

        severityList = severityList.concat(severityResponse.data);

        setSeverities(severityList);

        return {likelihoodList, severityList};
    }

    function calculateRiskScores (severity, likelihood, controls) {
        const riskScore = (severity * likelihood).toFixed(2);
        setRiskScoreUncontrolled({ riskScore });
        setRiskScoreControlled({ riskScore: getRiskScoreControlled(controls, riskScore) });
    }

    function getRiskScoreControlled (factors, riskScore) {
        riskScore = parseFloat(riskScore);

        factors?.forEach(hazardControl => {
            const controlFactor = hazardControl.hcControlTypeNavigation.ctFactor;
            riskScore = riskScore * controlFactor;
        });

        return riskScore.toFixed(2);
    }

    function cancelChanges () {
        navigate('/Hazards');
    }

    function createNewRisk () {
        window.location.href = '/Hazard';
    }

    async function onSave () {
        const updatedHazard = getUpdatedHazard();

        if (!isHazardCreated) {
            const temp = await hazardApiService.addNewHazard(updatedHazard);

            navigate('/Hazard/' + temp.data.hzId);
            setSnackbarProperties('Successfully added new Risk');
            setHazardId(temp.data.hzId);
        } else if (!disableSaveButton) {
            const updatedHazardResponse = await hazardApiService.updateHazard(updatedHazard);
            if(updatedHazardResponse.success) {
                setSnackbarProperties('Successfully saved the Risk');
                navigate('/Hazard/' + hazardId, { replace: true });
                setChanges([]);
            } else {
                setSnackbarProperties(`Error updating Risk`, 'error');
            }
        }
    }

    function getUpdatedHazard () {
        const formInputs = {
            hzRiskDescription: riskDescription,
            hzDepartmentName: department,
            hzEquipmentId: equipment,
            hzJob: job,
            hzLikelihood: likelihood.lkId,
            hzObligation: obligation,
            hzProcess: process,
            hzProject: selectedProject.projectId,
            hzSeverity: severity.svId,
            hzTask: task,
            hzSystemArea: riskSources.find(riskSource => riskSource.name === selectedRiskSource)?.id,
            hzNotes: notes,
            significant: isSignificant === 'true',
        };

        return { ...newHazard, ...formInputs };
    }

    const isHazardCreated = useMemo(() => hazardId !== '0' && hazardId !== undefined, [hazardId]);
    const disableSaveButton = useMemo(() => (isHazardCreated && Object.keys(changes).length === 0) && canChangeHazard, [Object.keys(changes).length, userToken]);

    function handleAddAttachment () {
        if (isHazardCreated) {
            setShowUploadModal(true);
        }
    }

    function handleAddControl () {
        if (isHazardCreated) {
            setShowAddControlModal(true);
        }
    }

    function getRiskScoreValue (value) {
        if (value < hazardRiskScore.LOW) {
            return {
                color: '#9ACD32', text: value + ' - Low',
            };
        } else if (value < hazardRiskScore.MEDIUM) {
            return {
                color: '#FFFF00', text: value + ' - Medium',
            };
        } else if (value < hazardRiskScore.HIGH) {
            return {
                color: '#FFA500', text: value + ' - High',
            };
        } else {
            return {
                color: '#FF0000', text: value + ' - Very High',
            };
        }
    }

    const updateProjects = (orgId) => {
        if (orgId) {
            locationApiService.getLocationsForOrg(orgId).then((projectResponse) => {
                const projectsFound = projectResponse.data.projects;

                if (projectsFound) {
                    setProjects(projectsFound);
                }
            });
        }
    };

    function handleProjectChange(event) {
        const projectId = projects.find(proj => proj.projectName === event.target.value)?.projectId;

        setSelectedProject({
            projectId: projectId,
            projectName: event.target.value,
        });

        const newValue = event.target.value;
        if(currentHazard?.hzProjectDto.pjName !== newValue) {
            setChanges({
                ...changes,
                'Project': newValue,
            });
        } else {
            removeChange('Project');
        }
        loadAutoCompleteFields(projectId);
    }

    function handleIsSignificantChange(event) {
        const newValue = event.target.value;
        setIsSignificant(newValue);

        if(currentHazard?.significant !== newValue) {
            setChanges({
                ...changes,
                'significant': newValue === 'true',
            });
        } else {
            removeChange('significant');
        }
    }

    function removeChange (objectToRemove) {
        const tempChangeArray = changes;
        delete tempChangeArray[objectToRemove];
        setChanges(tempChangeArray);
    }

    function getRiskDescriptionLabel() {
        console.log(selectedRiskSource);
        switch (selectedRiskSource) {
        case 'Environmental':
            return 'Environmental Aspect';
        case 'Quality':
            return 'Quality Defect';
        case 'OHS':
            return 'OHS Hazard';
        case 'Integrated/Multiple':
            return 'Integrated/Other Risk Source';
        case 'Other':
            return 'Other Risk';
        case 'Energy':
            return 'Energy Use';
        case 'CyberSecurity':
            return 'Cyber Risk';
        default:
            return 'Risk Source Description';
        }
    }

    return (
        <div className="edit-risk-container">
            <Form className="edit-risk-form">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={createNewRisk}
                        >
                            Create New Risk
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h1 className="edit-risk-title">{title}</h1>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box border={1} borderColor="grey.500" padding={useTheme().spacing(2)}>
                            {/* Edit Risk information */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="organization">Organization</Label>
                                        <Select
                                            disabled={!isSuperAdmin}
                                            name="organization"
                                            id="organization"
                                            value={organizations.length > 0 ? selectedOrg : ''}
                                            onChange={(event) => {
                                                const newValue = event.target.value;
                                                if(currentHazard?.hzProjectDto.pjOrgDto.ogName !== newValue) {
                                                    setChanges({
                                                        ...changes,
                                                        'Org': newValue,
                                                    });
                                                } else {
                                                    removeChange('Org');
                                                }
                                                setSelectedOrg(event.target.value);
                                                updateProjects(organizations.find(org => org.ogName === event.target.value)?.ogId);

                                                // Since the organization was just changed, we shouldn't have anything selected for project.
                                                setSelectedProject({ projectId: 0, projectName: '' });

                                            }}
                                        >
                                            {organizations.map((org) => (<MenuItem key={org.ogId} value={org.ogName}>
                                                {org.ogName}
                                            </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="project">Projects/Location</Label>
                                        <Select
                                            name="project"
                                            id="project"
                                            value={projects.length > 0 ? selectedProject.projectName : ''}
                                            onChange={handleProjectChange}
                                            disabled={!canChangeHazard}
                                        >
                                            {projects.map((project) => (
                                                <MenuItem key={project.projectId} value={project.projectName}>
                                                    {project.projectName}
                                                </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="department">Department</Label>
                                        <Autocomplete
                                            options={departmentAutocomplete}
                                            disabled={!canChangeHazard}
                                            value={department}
                                            onInputChange={(event, value) => {
                                                if(currentHazard?.hzDepartmentName !== value) {
                                                    setChanges({
                                                        ...changes,
                                                        'Department': value,
                                                    });
                                                } else {
                                                    removeChange('Department');
                                                }
                                                setDepartment(value);
                                            }}
                                            getOptionLabel={(option) => option}
                                            freeSolo={true}
                                            renderInput={(input) => (
                                                <TextField {...input} label=' ' variant="standard"/>)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="process">Process</Label>
                                        <Autocomplete
                                            disabled={!canChangeHazard}
                                            options={processAutocomplete}
                                            value={process}
                                            onInputChange={(event, value) => {
                                                if(currentHazard?.hzProcess !== value) {
                                                    setChanges({
                                                        ...changes,
                                                        'Process': value,
                                                    });
                                                } else {
                                                    removeChange('Process');
                                                }
                                                setProcess(value);
                                            }}
                                            getOptionLabel={(option) => option}
                                            freeSolo={true}
                                            renderInput={(input) => (
                                                <TextField {...input} label=' ' variant="standard"/>)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="job">Job</Label>
                                        <Autocomplete
                                            disabled={!canChangeHazard}
                                            options={jobAutocomplete}
                                            value={job}
                                            onInputChange={(event, value) => {
                                                if(currentHazard?.hzJob !== value) {
                                                    setChanges({
                                                        ...changes,
                                                        'Job': value,
                                                    });
                                                } else {
                                                    removeChange('Job');
                                                }
                                                setJob(value);
                                            }}
                                            getOptionLabel={(option) => option}
                                            freeSolo={true}
                                            renderInput={(input) => (
                                                <TextField {...input} label=' ' variant="standard"/>)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="task">Task</Label>
                                        <Autocomplete
                                            disabled={!canChangeHazard}
                                            options={taskAutocomplete}
                                            value={task}
                                            onInputChange={(event, value) => {
                                                if(currentHazard?.hzTask !== value) {
                                                    setChanges({
                                                        ...changes,
                                                        'Task': value,
                                                    });
                                                } else {
                                                    removeChange('Task');
                                                }
                                                setTask(value);
                                            }}
                                            getOptionLabel={(option) => option}
                                            freeSolo={true}
                                            renderInput={(input) => (
                                                <TextField {...input} label=' ' variant="standard"/>)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="equipment">Equipment</Label>
                                        <Autocomplete
                                            disabled={!canChangeHazard}
                                            options={equipmentAutocomplete}
                                            value={equipment}
                                            onInputChange={(event, value) => {
                                                if(currentHazard?.hzEquipmentId !== value) {
                                                    setChanges({
                                                        ...changes,
                                                        'Equipment': value,
                                                    });
                                                } else {
                                                    removeChange('Equipment');
                                                }
                                                setEquipment(value);
                                            }}
                                            getOptionLabel={(option) => option}
                                            freeSolo={true}
                                            renderInput={(input) => (
                                                <TextField {...input} label=' ' variant="standard"/>)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="riskSource">Risk Source</Label>
                                        <Select
                                            disabled={!canChangeHazard}
                                            name="riskSource"
                                            id="riskSource"
                                            value={selectedRiskSource}
                                            onChange={(event) => {
                                                const newValue = event.target.value;
                                                if(currentHazard?.hzSystemAreaDto.name !== newValue) {
                                                    setChanges({
                                                        ...changes,
                                                        'RiskSource': newValue,
                                                    });
                                                } else {
                                                    removeChange('RiskSource');
                                                }
                                                setSelectedRiskSource(newValue);
                                            }}
                                        >
                                            {riskSources.map((riskSource) => (
                                                <MenuItem key={riskSource.id} value={riskSource.name}>
                                                    {riskSource.name}
                                                </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle}>{getRiskDescriptionLabel()}</Label>
                                        <Input
                                            disabled={!canChangeHazard}
                                            type="text"
                                            value={riskDescription}
                                            onChange={(event) => {
                                                const newValue = event.target.value;
                                                if(currentHazard?.riskDescription !== newValue) {
                                                    setChanges({
                                                        ...changes,
                                                        'RiskDescription': newValue,
                                                    });
                                                } else {
                                                    removeChange('RiskDescription');
                                                }

                                                setRiskDescription(newValue);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* A grid item just to create some separation. */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor='significant-group'>Significant</Label>
                                        <RadioGroup
                                            row
                                            name="significant-group"
                                            value={isSignificant}
                                            onChange={handleIsSignificantChange}
                                        >
                                            <FormControlLabel disabled={!canChangeHazard} value={true} control={<Radio />} label={'Yes'} />
                                            <FormControlLabel disabled={!canChangeHazard} value={false} control={<Radio />} label={'No'} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="severity">Severity</Label>
                                        <Select
                                            disabled={!canChangeHazard}
                                            name="severity"
                                            id="severity"
                                            value={severity.svName}
                                            onChange={(event) => {
                                                const newValue = event.target.value;
                                                if(currentHazard?.hzSeverity !== severities.find(sev => sev.svName === newValue).svId) {
                                                    setChanges({
                                                        ...changes,
                                                        'Severity': newValue,
                                                    });
                                                } else {
                                                    removeChange('Severity');
                                                }
                                                const sevValue = severities.find(sev => sev.svName === newValue);
                                                setSeverity({
                                                    svId: sevValue?.svId,
                                                    svName: sevValue.svName,
                                                    svFactor: sevValue.svFactor,
                                                });

                                                if (sevValue) {
                                                    calculateRiskScores(sevValue.svFactor, likelihood.lkFactor, controls);
                                                }
                                            }}
                                        >
                                            {severities.map((sev) => (<MenuItem key={sev.svId} value={sev.svName}>
                                                {sev.svName}
                                            </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="likelihood">Likelihood </Label>
                                        <Select
                                            disabled={!canChangeHazard}
                                            name="likelihood"
                                            id="likelihood"
                                            value={likelihood.lkName}
                                            onChange={(event) => {
                                                const newValue = event.target.value;
                                                if(currentHazard?.hzLikelihood !== likelihoods.find(x => x.lkName === newValue).lkId) {
                                                    setChanges({
                                                        ...changes,
                                                        'Likelihood': newValue,
                                                    });
                                                } else {
                                                    removeChange('Likelihood');
                                                }
                                                const likelihoodValue = likelihoods.find(likely => likely.lkName === newValue);

                                                setLikelihood({
                                                    lkId: likelihoodValue?.lkId,
                                                    lkName: likelihoodValue.lkName,
                                                    lkFactor: likelihoodValue?.lkFactor,
                                                });

                                                if (likelihoodValue) {
                                                    calculateRiskScores(severity.svFactor, likelihoodValue.lkFactor, controls);
                                                }
                                            }}
                                        >
                                            {likelihoods.map((likely) => (
                                                <MenuItem key={likely.lkId} value={likely.lkName}>
                                                    {likely.lkName}
                                                </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="riskScoreUncontrolled">Risk Score (Uncontrolled)</Label>
                                        <InputBase
                                            readOnly
                                            fullWidth
                                            value={getRiskScoreValue(riskScoreUncontrolled.riskScore).text}
                                            style={{
                                                border: '1px solid #ccc',
                                                borderRadius: 4,
                                                padding: '10px 12px',
                                                fontSize: 16,
                                                backgroundColor: getRiskScoreValue(riskScoreUncontrolled.riskScore).color,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="compliance">Compliance Obligation</Label>
                                        <Input
                                            disabled={!canChangeHazard}
                                            type="text"
                                            name="compliance"
                                            id="compliance"
                                            value={obligation}
                                            onChange={(event) => {
                                                const newValue = event.target.value;
                                                if(currentHazard?.hzObligation !== newValue) {
                                                    setChanges({
                                                        ...changes,
                                                        'Obligation': newValue,
                                                    });
                                                } else {
                                                    removeChange('Obligation');
                                                }
                                                setObligation(newValue);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor="riskScoreControlled">Risk Score (Controlled)</Label>
                                        <InputBase
                                            readOnly
                                            fullWidth
                                            value={getRiskScoreValue(riskScoreControlled.riskScore).text}
                                            style={{
                                                border: '1px solid #ccc',
                                                borderRadius: 4,
                                                padding: '10px 12px',
                                                fontSize: 16,
                                                backgroundColor: getRiskScoreValue(riskScoreControlled.riskScore).color,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <Label style={labelStyle} htmlFor={'notes'}>Notes</Label>
                                        <CKEditor
                                            disabled={!canChangeHazard}
                                            editor={ClassicEditor}
                                            style={{ height: '200px', width: '100%' }}
                                            config={{
                                                toolbar: {
                                                    items: [ 'heading',
                                                        '|',
                                                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                        '|',
                                                        'bold', 'italic', 'link', 'bulletedList', 'numberedList',
                                                        '|',
                                                        'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock',
                                                        '|',
                                                        'todoList', 'outdent', 'indent', 'undo', 'redo' ]
                                                },
                                                plugins: [
                                                    Bold, Essentials, Italic, Mention, Paragraph, Undo, Link, List, Heading, Indent, MediaEmbed, Table, FontFamily, FontSize, BlockQuote, AutoLink, LinkEditing, LinkUI
                                                ],
                                                link: {
                                                    addTargetToExternalLinks: true,
                                                },
                                                mediaEmbed: {
                                                    previewsInData: true,

                                                }
                                            }}
                                            data={notes ?? ''}
                                            onChange={(event, editor) => {
                                                const newValue = editor.getData();
                                                if(currentHazard?.hzNotes !== newValue) {
                                                    setChanges({
                                                        ...changes,
                                                        'Notes': newValue,
                                                    });
                                                } else {
                                                    removeChange('Notes');
                                                }
                                                setNotes(newValue);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: '20px', display: 'flex', gap: '16px' }}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ background: 'white', color: 'black' }}
                                    onClick={cancelChanges}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    disabled={disableSaveButton}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ position: 'relative' }}>
                                <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                                    Controls
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleAddControl}
                                        disabled={!isHazardCreated}
                                    >
                                        Add Control
                                    </Button>
                                    {isHazardCreated
                                        ? (<RiskControlModal
                                            onClose={handleAddControlModalClose}
                                            open={showAddControlModal}
                                            controlTypes={controlTypes}
                                            hazardId={hazardId}
                                        />)
                                        : (<></>)}
                                </div>
                                <DataGridPro
                                    rows={controls}
                                    autoHeight
                                    localeText={{ noRowsLabel: 'No Controls Currently Available for this Risk' }}
                                    columns={controlsColumns}
                                    getRowHeight={() => 'auto'}
                                    getRowId={(row) => row.hcId}
                                />
                                {controls.length > 0
                                    ? (<ConfirmDeleteDialogHazard
                                        keepMounted
                                        open={showControlDeleteDialog}
                                        onClose={handleDeleteControl}
                                        deleteTitle={'Confirm Control Delete'}
                                        deleteLabel={`Are you sure you want to delete the current control with the description [${currentControl.hcDescription}]?`}
                                    ></ConfirmDeleteDialogHazard>)
                                    : (<> </>)}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography marginTop="10px" variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                                Files
                            </Typography>
                            <DataGridPro
                                rows={files}
                                autoHeight
                                localeText={{ noRowsLabel: 'No Files Currently Available for this Risk' }}
                                columns={fileColumns}
                                getRowHeight={() => 'auto'}
                                getRowId={(row) => row.id}
                            />
                            {files.length > 0
                                ? (<ConfirmDeleteDialogHazard
                                    keepMounted
                                    open={showFileDeleteDialog}
                                    onClose={onFileDeleteClick}
                                    deleteTitle={'Confirm Attachment Delete'}
                                    deleteLabel={`Are you sure you want to delete the current attachment [${currentFile.filename}]?`}
                                ></ConfirmDeleteDialogHazard>)
                                : (<></>)}
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ position: 'relative' }}>
                                <Typography marginTop="10px" variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                                    Images
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        disabled={!isHazardCreated}
                                        startIcon={<AttachFileOutlinedIcon/>}
                                        onClick={handleAddAttachment}
                                    >
                                        Add Attachment
                                    </Button>
                                    {images.length > 0
                                        ? (<>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                startIcon={<DeleteOutlinedIcon/>}
                                                color="error"
                                                right={1}
                                                sx={{ marginLeft: '10px' }}
                                                onClick={handleFileDelete}
                                            >
                                            Delete Attachment
                                            </Button>
                                            <ConfirmDeleteDialogHazard
                                                keepMounted
                                                open={showImageDeleteDialog}
                                                onClose={onImageDeleteClick}
                                                deleteTitle={'Confirm Attachment Delete'}
                                                deleteLabel={`Are you sure you want to delete the current attachment [${images[currentImage].filename}]?`}
                                            ></ConfirmDeleteDialogHazard>
                                        </>)
                                        : (<> </>)}
                                </div>
                                <FileUploadModal
                                    onClose={handleFileUpload}
                                    open={showUploadModal}
                                    imageButton={true}
                                />
                                {images.length > 0
                                    ? (<React.Fragment>
                                        <div style={{ position: 'relative' }}>
                                            <Image src={images.length > 0 ? images[currentImage].blobUrl : ''} alt={''}
                                                style={{ width: '100%' }} onLoad={handleLoad}/>
                                            {loading && <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CircularProgress/>
                                            </div>}
                                        </div>
                                        {images.length > 1
                                            ? (<>
                                                <Button onClick={handlePrev} style={{
                                                    backgroundColor: 'white',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '0%',
                                                    opacity: 0.5,
                                                }}>
                                                    <ArrowBack/>
                                                </Button>
                                                <Button onClick={handleNext} style={{
                                                    backgroundColor: 'white',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '0%',
                                                    opacity: 0.5,
                                                }}>
                                                    <ArrowForward/>
                                                </Button>
                                            </>)
                                            : (<></>)}
                                    </React.Fragment>)
                                    : (<Grid item xs={12}>
                                        <Box sx={{ bgcolor: '#bee5eb', color: 'black', p: 2 }}>
                                            <Typography variant="h6">No images found</Typography>
                                        </Box>
                                    </Grid>)}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <Typography variant="h4" component="h2" sx={{ marginTop: '16px', fontWeight: 'bold', mb: 2 }}>
                        Risk Changes
                    </Typography>
                    <Button sx={{marginBottom: '10px'}} variant={'contained'} onClick={() => setShowRiskChanges(!showRiskChanges)}>{showRiskChanges ? 'Hide risk changes' : 'show risk changes'}</Button>
                    {showRiskChanges && <DataGridPro
                        rows={hazardAudits}
                        autoHeight
                        localeText={{ noRowsLabel: 'No changes to Risk' }}
                        columns={hazardAuditColumns}
                        getRowHeight={() => 'auto'}
                        getRowId={(row) => row.id}
                    />
                    }
                </Box>
            </Form>
        </div>);
};

Hazard.propTypes = {
    setSnackbarProperties: PropTypes.func,
};
export default Hazard;

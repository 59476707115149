import { Label } from 'reactstrap';
import Box from '@mui/material/Box';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TaskHistoryDto from '../../dtos/Task/TaskHistoryDto';
import { Grid } from '@mui/material';
import TaskCommentButtonBar from './TaskCommentButtonBar';
import EditCommentModal from '../Modal/EditCommentModal';
import ConfirmDeleteDialog from '../Modal/ConfirmDelete';
import TaskApiService from '../../api/TaskApiService';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function TaskAttachment(props) {
    const { showImages, taskChange, setTaskChange, setSnackbarProperties, handleDeleted } = props;
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [fullSizeImage, setFullSizeImage] = useState(false);

    const [originalValue, setOriginalValue] = useState('');

    const showAttachment = useMemo(() => showImages && taskChange.contentType.includes('image'), [showImages, taskChange]);

    const taskApiService = new TaskApiService();

    function handleEditComment() {
        setShowEditDialog(true)
    }

    function handleDeleteComment() {
        setShowDeleteDialog(true);
    }

    function handleEditCancel() {
        setTaskChange(originalValue);
        setShowEditDialog(false);
    }

    function handleCommentChange(value) {
        setTaskChange(value);
    }

    async function handleSaveEdit() {
        const response = await taskApiService.updateTaskAttachment(taskChange.taskChangeId, taskChange);

        if(response && response.success) {
            setSnackbarProperties('Successfully updated Attachment');
            setShowEditDialog(false);
        } else {
            setSnackbarProperties('Error updating Attachment', 'error');
        }
    }

    async function handleConfirmDelete() {
        const response = await taskApiService.deleteTaskAttachment(taskChange.taskChangeId);

        if(response && response.success) {
            setSnackbarProperties('Successfully deleted Attachment');
        } else {
            setSnackbarProperties('Error deleting Attachment', 'error');
        }
        setShowDeleteDialog(false);
        handleDeleted();
    }

    function handleFullSizeImageClick() {
        setFullSizeImage(!fullSizeImage);
    }

    useEffect(() => {
        setOriginalValue(`${taskChange.taskChange}`);
    }, []);

    return(
        <Box
            sx={{
                width: '100%',
                border: '1px solid #ddd',
                margin: '10px',
                backgroundColor: 'white',
                padding: '5px',
                display: 'fixed',
            }}
        >
            <Grid container>
                <Grid item xs={10}>
                    <Label
                        style={{ fontWeight: 'bold' }}
                    >
                        {taskChange?.taskChangeType} posted by {taskChange?.taskChangeUser} on {taskChange?.taskChangeTime}
                    </Label>
                </Grid>
                <Grid item xs={2}>
                    <TaskCommentButtonBar handleEditComment={handleEditComment} handleDeleteComment={handleDeleteComment} style={{marginRight: '2px', marginLeft: 'auto'}} />
                </Grid>
                {showAttachment &&
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={ 12 }>
                                <a href={ taskChange.url } target={ '_blank' } rel="noreferrer">{ taskChange.taskChange }</a>
                            </Grid>
                            <Grid item xs={1}>
                                {fullSizeImage ? <RemoveIcon onClick={handleFullSizeImageClick} /> : <AddIcon onClick={handleFullSizeImageClick} />}
                            </Grid>
                            <Grid item xs={ 11 }>
                                <img src={taskChange.url} style={{maxWidth: fullSizeImage ? '100%' : '20%'}} alt={''}/>
                            </Grid>
                        </Grid>
                    </Grid>
                }

            </Grid>
            <EditCommentModal
                open={showEditDialog}
                cancel={handleEditCancel}
                comment={taskChange.taskChange}
                setComment={handleCommentChange}
                save={handleSaveEdit}
                originalValue={originalValue}
                useEditor={false}
            />
            <ConfirmDeleteDialog
                onClose={handleConfirmDelete}
                open={showDeleteDialog}
                title={'Delete Attachment'}
                content={'Are you sure you want to delete this attachment?'}
            />
        </Box>
    )
}

TaskAttachment.propTypes = {
    showImages: PropTypes.bool.isRequired,
    taskChange: PropTypes.objectOf(TaskHistoryDto),
    setTaskChange: PropTypes.func.isRequired,
    setSnackbarProperties: PropTypes.func.isRequired,
}

import jwtDecode from 'jwt-decode';

export default class AuthUtil {
    static getUsername(userToken) {
        if(!userToken) {
            return '';
        }
        return jwtDecode(userToken).unique_name ?? '';
    }

    static isAdmin(userToken) {
        return userToken && (jwtDecode(userToken).Admin === 'True');
    }

    static isAuthorized(userToken) {
        return !!userToken;
    }

    static isTokenExpired(userToken) {
        if(!userToken) {
            return true;
        }
        const { exp } = jwtDecode(userToken);

        return Date.now() > (exp * 1000);
    }

    static getUserId(userToken) {
        if(!userToken) {
            return '';
        }
        return jwtDecode(userToken).nameid ?? '';
    }

    static canChangeHazards(userToken) {
        if(!userToken) {
            return false;
        }

        return jwtDecode(userToken).CanChangeHazards === 'True';
    }

    static getProjectUserXRefs(userToken) {
        const tokenDecode = jwtDecode(userToken);
        const permissions = tokenDecode.ProjectPermissions;
        let parsedPermissions = [];

        if(Array.isArray(permissions)) {
            permissions.forEach((perm) => parsedPermissions.push(JSON.parse(perm.replaceAll('\'', '"'))));
        } else {
            parsedPermissions.push(JSON.parse(permissions.replaceAll('\'', '"')));
        }

        return parsedPermissions;
    }

    static isSuperAdmin(userToken) {
        const tokenDecode = jwtDecode(userToken);
        return tokenDecode.SuperAdmin === 'True';
    }

    getUsersOrgId(userToken) {
        const tokenDecode = jwtDecode(userToken);
        return tokenDecode.sid;
    }
}
